<div class="w-full max-w-screen-xl mx-auto px-14 py-7" [ngClass]="classes">
  <header class="grid grid-cols-{{ widget.numberOfColumns }} gap-4 mb-4">
    <h5 class="col-span-{{ widget.numberOfColumns - 1 }} pb-3 widget-title">
      <span class="pb-3">{{ widget.name }}</span>
    </h5>
    <a
      href="#"
      class="col-span-1 text-primary flex items-center justify-end text-base font-semibold hover:opacity-50 hover:transition-all"
    >
      {{ "Ver tudo" | transloco }}
      <mat-icon
        svgIcon="mat_solid:arrow_forward_ios"
        class="text-primary icon-size-4 ml-2"
      ></mat-icon>
    </a>
  </header>

  <div class="grid grid-cols-{{ widget.numberOfColumns }} gap-4">
    <ng-container *ngIf="widget?.items?.data">
      <classified-card
        class="col-span-auto"
        *ngFor="let item of widget.items.data"
        [widget]="widget"
        [item]="item"
      ></classified-card>
    </ng-container>
    <ng-container *ngIf="items">
      <classified-card
        class="col-span-auto"
        *ngFor="let item of items"
        [widget]="widget"
        [item]="item"
      ></classified-card>
    </ng-container>
  </div>
</div>
