<div class="tx-card__wrapper {{ classes }} w-full" *ngIf="widget && item">
  <a
    [routerLink]="[
      '/',
      activeLang,
      'artigos',
      item?.attributes.article_categories?.data[0]?.attributes.slug,
      item.attributes.slug + '-p' + item.id,
    ]"
    class="tx-wide-card__img w-full h-50 mb-4 flex"
    [ngClass]="{
      'sm:hidden': showImage !== undefined && (!showImage ?? !widget.showImage),
    }"
  >
    <div
      class="h-50 w-full bg-slate-300"
      *ngIf="!item?.attributes.featureImage?.data"
    ></div>
    <img
      [src]="getImageUrl(item?.attributes.featureImage?.data)"
      [alt]="item?.attributes.featureImage?.data.name"
      *ngIf="item?.attributes.featureImage?.data"
      class="object-cover w-full h-full object-center"
      loading="lazy"
    />
  </a>

  <div class="tx-card__content px-0">
    <a
      [routerLink]="[
        '/',
        activeLang,
        'artigos',
        item?.attributes.article_categories?.data[0]?.attributes.slug,
        item.attributes.slug + '-p' + item.id,
      ]"
    >
      <h4
        class="text-black-motorbest-500 font-normal leading-3xl"
        *ngIf="widget.showTitle"
      >
        {{ item.attributes.title }}
      </h4>
    </a>
    <div class="flex gap-2 items-center mt-1">
      <a
        [routerLink]="[
          '/',
          activeLang,
          'artigos',
          item?.attributes.article_categories?.data[0]?.attributes.slug,
          item.attributes.slug + '-p' + item.id,
        ]"
      >
        <small
          class="uppercase text-xs leading-2xl font-medium text-gray40-500 flex"
          *ngIf="showDate !== undefined ? showDate : widget.showDate"
        >
          {{ item.attributes.publishDate | date }}
        </small>
      </a>
      <ng-container *ngIf="item?.attributes.article_categories?.data">
        <div *ngIf="showDate !== undefined ? showDate : widget.showDate">|</div>
        <a
          [routerLink]="['/', activeLang, 'artigos', category.attributes.slug]"
          class="uppercase text-primary text-xs font-semiboldleading-2xl"
          *ngFor="
            let category of item?.attributes.article_categories?.data;
            let last = last
          "
        >
          {{ category.attributes.friendlyName }}<span *ngIf="!last">,</span>
        </a>
      </ng-container>
    </div>
    <a
      [routerLink]="[
        '/',
        activeLang,
        'artigos',
        item?.attributes.article_categories?.data[0]?.attributes.slug,
        item.attributes.slug + '-p' + item.id,
      ]"
    >
      <div
        class="text-md text-gray60-500 leading-2xl mt-4 overflow-hidden h-20 text-ellipsis"
        [innerHTML]="item.attributes?.excerpt"
        *ngIf="widget.showExcerpt"
      ></div>
    </a>
  </div>
</div>
