<div
  class="max-w-screen-xl mx-auto px-4 md:px-14 py-7"
  [ngClass]="classes"
  *ngIf="layoutFormat.Grid1x3 === widget.layoutFormat"
  [id]="widget.layoutFormat"
>
  <header class="grid grid-cols-1 md:grid-cols-4 gap-4 pb-4">
    <h5 class="col-span-1 md:col-span-2 widget-title" *ngIf="!isScreenSmall">
      <span class="pb-3">{{ widget.name }}</span>
    </h5>
    <h4 class="col-span-1 md:col-span-2 widget-title" *ngIf="isScreenSmall">
      <span class="pb-3">{{ widget.name }}</span>
    </h4>
    <a
      [routerLink]="['/', activeLang, 'artigos', newsLatestSlug]"
      class="col-span-1 text-primary items-center justify-end text-base font-semibold hover:opacity-50 hover:transition-all hidden md:flex"
    >
      {{ "See all articles" | transloco }}
      <mat-icon
        svgIcon="mat_solid:arrow_forward_ios"
        class="text-primary icon-size-4 ml-2"
      ></mat-icon>
    </a>
  </header>

  <div class="grid grid-cols-4 gap-4" *ngIf="widget.items.length">
    <ng-container
      *ngFor="
        let item of widget.items;
        let i = index;
        let last = last;
        let first = first
      "
    >
      <widget-new-wide-card
        class="col-span-3"
        [widget]="widget"
        [item]="item"
        *ngIf="first"
      ></widget-new-wide-card>
      <card-ads
        class="col-span-1"
        [widget]="'/assets/demo/revista.jpg'"
        *ngIf="widget.hasAdverts && first"
        [position]="adsPosition"
        [page]="adsPage"
      ></card-ads>
      <widget-spacer class="col-span-3" *ngIf="first"></widget-spacer>
      <div class="col-span-1" *ngIf="first"></div>
      <widget-news-card
        class="col-span-1"
        [widget]="widget"
        [item]="item"
        *ngIf="!first"
      ></widget-news-card>
      <card-ads
        class="col-span-1"
        [widget]="'/assets/demo/bmw.jpg'"
        *ngIf="widget.hasAdverts && last"
        [position]="adsPosition"
        [page]="adsPage"
      ></card-ads>
    </ng-container>
  </div>
</div>

<div
  class="max-w-screen-xl mx-auto px-4 md:px-14 py-7"
  [ngClass]="classes"
  *ngIf="layoutFormat.GridXLine === widget.layoutFormat"
  [id]="widget.layoutFormat"
>
  <header class="grid grid-cols-1 md:grid-cols-4 gap-4 pb-4">
    <ng-container *ngIf="showListTitle">
      <h5 class="col-span-1 md:col-span-2 widget-title" *ngIf="!isScreenSmall">
        <span class="pb-3">{{ widget.name }}</span>
      </h5>
      <h4 class="col-span-1 md:col-span-2 widget-title" *ngIf="isScreenSmall">
        <span class="pb-3">{{ widget.name }}</span>
      </h4>
    </ng-container>
    <a
      [routerLink]="['/', activeLang, 'artigos', newsLatestSlug]"
      class="col-span-1 text-primary hidden md:flex items-center justify-end text-base font-semibold hover:opacity-50 hover:transition-all"
      *ngIf="showSeeAllLink"
    >
      {{ "See all articles" | transloco }}
      <mat-icon
        svgIcon="mat_solid:arrow_forward_ios"
        class="text-primary icon-size-4 ml-2"
      ></mat-icon>
    </a>
  </header>

  <div
    class="grid grid-cols-1 md:grid-cols-4 gap-6 md:gap-4 mt-6 md:mt-0"
    *ngIf="widget.items?.data?.length ?? widget.items?.length"
  >
    <ng-container
      *ngFor="
        let item of widget.items?.data ?? widget?.items;
        let i = index;
        let last = last;
        let first = first
      "
    >
      <widget-news-card
        class="col-span-1"
        [widget]="widget"
        [item]="item"
      ></widget-news-card>

      <a
        [routerLink]="['/', activeLang, 'artigos', newsLatestSlug]"
        *ngIf="last && isScreenSmall && showSeeAllLink"
        class="col-span-1 text-primary flex md:hidden items-center justify-center text-base font-semibold hover:opacity-50 hover:transition-all mt-6 mb:mt-4"
      >
        {{ "See all articles" | transloco }}
        <mat-icon
          svgIcon="mat_solid:arrow_forward_ios"
          class="text-primary icon-size-4 ml-2"
        ></mat-icon>
      </a>

      <div
        *ngIf="last && isScreenSmall && showSeeAllLink"
        class="flex w-full col-span-1 border-t border-gray-200 mt-2 mb-5"
      ></div>

      <card-ads
        class="md:col-start-4 col-span-1"
        [widget]="'/assets/demo/bmw.jpg'"
        *ngIf="widget.hasAdverts && last"
        [position]="adsPosition"
        [page]="adsPage"
      ></card-ads>
    </ng-container>
  </div>
</div>

<div
  class="max-w-screen-xl mx-auto px-4 md:px-14 py-7"
  [ngClass]="classes"
  *ngIf="layoutFormat.CesarGrid === widget.layoutFormat"
  [id]="widget.layoutFormat"
>
  <header class="grid grid-cols-1 md:grid-cols-4 gap-6 md:gap-4 pb-4">
    <h5 class="col-span-1 md:col-span-2 widget-title" *ngIf="!isScreenSmall">
      <span class="pb-3">{{ widget.name }}</span>
    </h5>
    <h4 class="col-span-1 md:col-span-2 widget-title" *ngIf="isScreenSmall">
      <span class="pb-3">{{ widget.name }}</span>
    </h4>
    <a
      [routerLink]="['/', activeLang, 'artigos', newsLatestSlug]"
      class="col-span-1 text-primary hidden md:flex items-center justify-end text-base font-semibold hover:opacity-50 hover:transition-all"
    >
      {{ "See all articles" | transloco }}
      <mat-icon
        svgIcon="mat_solid:arrow_forward_ios"
        class="text-primary icon-size-4 ml-2"
      ></mat-icon>
    </a>
  </header>
  <div
    class="grid grid-cols-1 md:grid-cols-4 gap-6 md:gap-4 mt-6 md:mt-0"
    *ngIf="widget.items.length"
  >
    <widget-new-wide-card
      class="col-span-1 md:col-span-2 border-b md:border-b-0 md:border-r border-gray20-500 md:pr-6"
      orientation="vertical"
      [widget]="widget"
      [item]="widget.items[0]"
      *ngIf="widget.items[0]"
    ></widget-new-wide-card>

    <div
      class="col-span-1 md:col-span-2 grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-4"
    >
      <ng-container
        *ngFor="
          let item of widget.items.slice(1, 5);
          let i = index;
          let last = last;
          let first = first
        "
      >
        <widget-news-card
          class="col-span-1"
          [widget]="widget"
          [item]="item"
          [showImage]="i < 2"
          [showDate]="true"
        ></widget-news-card>
        <widget-spacer
          class="col-span-1 md:col-span-2"
          *ngIf="i === 1 && !last && i > 0"
        ></widget-spacer>
      </ng-container>
    </div>

    <widget-spacer class="col-span-1 md:col-span-3"></widget-spacer>

    <div class="col-span-1">
      <!-- EMPTY -->
    </div>

    <ng-container
      *ngFor="
        let item of widget.items.slice(5, 8);
        let i = index;
        let last = last;
        let first = first
      "
    >
      <widget-news-card
        class="col-span-1"
        [widget]="widget"
        [item]="item"
        [showDate]="true"
      ></widget-news-card>
    </ng-container>

    <a
      [routerLink]="['/', activeLang, 'artigos', newsLatestSlug]"
      *ngIf="isScreenSmall"
      class="col-span-1 text-primary flex md:hidden items-center justify-center text-base font-semibold hover:opacity-50 hover:transition-all mt-6 mb:mt-4"
    >
      {{ "See all articles" | transloco }}
      <mat-icon
        svgIcon="mat_solid:arrow_forward_ios"
        class="text-primary icon-size-4 ml-2"
      ></mat-icon>
    </a>
    <div
      *ngIf="isScreenSmall"
      class="flex w-full col-span-1 border-t border-gray-200 mt-2 mb-5"
    ></div>

    <card-ads
      class="md:col-start-4 col-span-1"
      [widget]="'/assets/demo/bmw.jpg'"
      *ngIf="widget.hasAdverts"
      [position]="adsPosition"
      [page]="adsPage"
    ></card-ads>
  </div>
</div>
