<div class="tx-card__wrapper {{ classes }} w-full mb-11">
  <a
    [routerLink]="[
      '/',
      activeLang,
      'marketplace',
      item?.attributes.product_category?.data.attributes.slug,
      item.attributes.slug + '-p' + item.id,
    ]"
  >
    <div
      class="tx-wide-card__img h-[200px] w-full mb-4"
      *ngIf="widget.showImage"
    >
      <div
        class="max-h-[270px] w-full bg-slate-300"
        *ngIf="!item?.attributes.images?.data"
      ></div>
      <img
        [src]="getImageUrl(item?.attributes.images?.data[0])"
        [alt]="item?.attributes.images?.data[0].attributes.caption"
        *ngIf="item?.attributes.images?.data[0]"
        class="object-cover h-full object-center"
        loading="lazy"
      />
    </div>

    <div
      class="text-xl leading-3xl font-semibold text-center mb-2"
      *ngIf="widget.showName"
    >
      {{ item.attributes.year }} {{ item.attributes.name }}
    </div>

    <div
      class="text-md leading-2xl font-semibold text-center mb-2"
      *ngIf="widget.showPrice"
    >
      <ng-container *ngIf="!item.attributes.price">{{
        "Sob Consulta" | transloco
      }}</ng-container>
      <ng-container *ngIf="item.attributes.price">{{
        item.attributes.price | currency: "EUR"
      }}</ng-container>
    </div>
    <div
      class="text-md leading-base font-semibold text-center"
      *ngIf="widget.showCity"
    >
      {{ item.attributes.city.data.attributes.name }},
      {{ item.attributes.city.data.attributes.country }}
    </div>
  </a>
</div>
