<ng-container *ngIf="adsToShow$ | async as adsItem">
  {{ log(adsItem) }}
  <small class="text-md leading-base text-gray30-500 flex justify-end mb-2">{{
    "PUB" | transloco
  }}</small>
  <Image
    [src]="getImageUrl(adsItem.attributes.Asset.data, true)"
    alt="{{ 'Motorbest' | transloco }}"
    [href]="adsItem.attributes.link"
    [target]="adsItem.attributes.target"
    [class]="'w-full h-auto object-contain'"
  >
  </Image>
</ng-container>
