<div [class]="classes">
  <!-- <a [routerLink]="[widget.url]" *ngIf="widget?.url">
    <img [src]="getImageUrl(widget.image)" alt="{{ 'Motorbest' | transloco }}" [class]="imageClasses" />
  </a>
  <img [src]="getImageUrl(widget.image)" alt="{{ 'Motorbest' | transloco }}" [class]="imageClasses"
    *ngIf="!widget?.url" /> -->

  <Image
    [src]="getImageUrl(widget.image)"
    alt="{{ 'Motorbest' | transloco }}"
    [class]="imageClasses"
    [href]="widget.url"
  ></Image>
</div>
