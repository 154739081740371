<h5
  class="col-span-8 col-start-3 mb-2 {{ widget.classesCss }} "
  *ngIf="widget?.showTitle"
>
  {{ widget.title }}
</h5>
<ng-container *ngIf="widget.Layout === 'Masonry'">
  <div class="grid{{ widget.id }}">
    <ng-container *ngIf="!isScreenSmall">
      <div
        class="grid-item grid-item-{{ widget.Columns }}"
        *ngFor="let item of widget.assets.data"
        [ngStyle]="{
          width:
            'calc(100% / ' +
            widget.Columns +
            ' - ' +
            widget.spaceBetween +
            'px)',
        }"
      >
        <Image
          [src]="getImageUrl(item, true)"
          alt="{{ widget.title | transloco }}"
          (imageLoad)="onImageLoad($event)"
        >
        </Image>
      </div>
    </ng-container>
    <ng-container *ngIf="isScreenSmall">
      <div
        class="grid-item grid-item-{{ widget.Columns }}"
        *ngFor="let item of widget.assets.data"
        [ngStyle]="{
          'max-width': '100%',
        }"
      >
        <Image
          [src]="getImageUrl(item, true)"
          alt="{{ widget.title | transloco }}"
          (imageLoad)="onImageLoad($event)"
        >
        </Image>
      </div>
    </ng-container>
  </div>
</ng-container>
<ng-container *ngIf="widget.Layout === 'Grid'">
  <div
    class="grid col-span-1 md:grid-cols-{{ widget.Columns }} gap-{{
      widget.spaceBetween
    }}"
  >
    <ng-container *ngIf="widget.Columns === widget.assets.data.length">
      <div
        *ngFor="
          let item of widget.assets.data;
          let first = first;
          let last = last
        "
      >
        <Image
          [src]="getImageUrl(item, true)"
          alt="{{ widget.title | transloco }}"
        >
        </Image>
      </div>
    </ng-container>

    <ng-container *ngIf="widget.Columns / widget.assets.data.length !== 1">
      <ng-container
        *ngFor="
          let item of widget.assets.data;
          let first = first;
          let last = last
        "
      >
        <div
          class="tx-grid {{
            getFullColumns(first && widget.positionFeatureImage === 'first')
          }}"
          *ngIf="first && widget.positionFeatureImage === 'first'"
        >
          <Image
            [src]="getImageUrl(item, true)"
            alt="{{ widget.title | transloco }}"
          >
          </Image>
        </div>

        <div
          class="tx-grid {{
            getFullColumns(last && widget.positionFeatureImage === 'last')
          }}"
          *ngIf="last && widget.positionFeatureImage === 'last'"
        >
          <Image
            [src]="getImageUrl(item, true)"
            alt="{{ widget.title | transloco }}"
          >
          </Image>
        </div>

        <div
          class="tx-grid"
          *ngIf="
            (widget.positionFeatureImage === 'first' && !first) ||
            (widget.positionFeatureImage === 'last' && !last)
          "
        >
          <Image
            [src]="getImageUrl(item, true)"
            alt="{{ widget.title | transloco }}"
          >
          </Image>
        </div>
      </ng-container>
    </ng-container>
  </div>
</ng-container>
