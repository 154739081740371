import { Injectable } from '@angular/core';
import { ApiService } from '@targx/services/api/api.service';
import qs from 'qs';
import { ReplaySubject, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FacetService {
  public onFacetsChanged: ReplaySubject<any> = new ReplaySubject<any>(1);

  private _facets: any;

  constructor(private api: ApiService) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  get facets$(): any {
    return this.onFacetsChanged.asObservable();
  }

  get facets(): any {
    return this._facets;
  }

  set facets(value: any) {
    this._facets = value;
    console.log(
      '🚀 ~ file: facet.service.ts:31 ~ FacetService ~ setfacets ~ this._facets:',
      this._facets,
    );
    this.onFacetsChanged.next(this._facets);
  }

  getFacetByKey(key: string): any {
    return this._facets[key];
  }

  get() {
    const data: any = {
      populate: '*',
    };

    const dataQuery = qs.stringify(data, {
      encodeValuesOnly: true,
    });

    return this.api.get(`/facets`, '', '?' + dataQuery, false).pipe(
      tap((result: any) => {
        const facets = [];
        result.data.forEach((facet) => {
          facets[facet.attributes.facetKey] = facet.attributes;
        });

        this.facets = facets;
      }),
    );
  }

  getAttributes() {
    const data: any = {};

    const dataQuery = qs.stringify(data, {
      encodeValuesOnly: true,
    });

    return this.api.get(`/attributes`, '', '?' + dataQuery, false).pipe(
      tap((result: any) => {
        const facets = [];
        result.data.forEach((facet) => {
          facets[facet.attributes.facetKey] = facet.attributes;
        });

        this.facets = facets;
      }),
    );
  }
}
