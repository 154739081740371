import { inject, Injectable } from '@angular/core';
import { MailchimpService } from './mailchimp.service';

@Injectable({
  providedIn: 'root',
})
export class NewsletterService {
  private _provider: string;
  private _service: any;
  private providers = {
    mailchimp: inject(MailchimpService),
  };

  constructor() {}

  set provider(value: string) {
    this._provider = value;
    this._service = this.providers[value];
  }

  get provider(): string {
    return this._provider;
  }

  get service(): any {
    return this._service;
  }

  addNewMember(params: any) {
    return this._service.addNewMember(params);
  }
}
