import { Injectable } from '@angular/core';
import { ApiService } from '@targx/services/api/api.service';

@Injectable({
  providedIn: 'root',
})
export class FormService {
  constructor(private api: ApiService) {}

  get(id) {
    return this.api.get(`formularios/${id}`);
  }

  send(data) {
    return this.api.post(`/ezforms/submit`, data);
  }
}
