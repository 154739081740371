import { Component, Input, OnDestroy, inject } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslocoService } from '@ngneat/transloco';
import { environment } from 'environments/environment';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-widget-base',
  templateUrl: './widget-base.component.html',
  styleUrls: ['./widget-base.component.scss'],
})
export class WidgetBaseComponent implements OnDestroy {
  @Input() widget?: any;
  @Input() classes?: string;
  @Input() wrapper = 'div';
  @Input() elementClasses?: string;

  public _unsubscribeAll: Subject<any> = new Subject<any>();

  public sanitizer: DomSanitizer = inject(DomSanitizer);

  public translocoSevice: any = inject(TranslocoService);

  public activeLang: string = this.translocoSevice.getActiveLang();

  getImageUrl(image, relative = false) {
    if (relative) return `${image?.attributes?.url}` || '';

    return `${environment.assets}${image?.attributes?.url}` || '';
  }

  getUrl(url, relative = false) {
    if (relative) return `${url}` || '';

    return `${environment.assets}${url}` || '';
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  log(s) {
    console.log(s);
  }

  sanitizeHtml(s) {
    return this.sanitizer.bypassSecurityTrustHtml(s);
  }
}
