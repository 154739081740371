import { Injectable } from '@angular/core';
import { IsActiveMatchOptions } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class FuseUtilsService {
  /**
   * Constructor
   */
  constructor() {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get the equivalent "IsActiveMatchOptions" options for "exact = true".
   */
  get exactMatchOptions(): IsActiveMatchOptions {
    return {
      paths: 'exact',
      fragment: 'ignored',
      matrixParams: 'ignored',
      queryParams: 'exact',
    };
  }

  /**
   * Get the equivalent "IsActiveMatchOptions" options for "exact = false".
   */
  get subsetMatchOptions(): IsActiveMatchOptions {
    return {
      paths: 'subset',
      fragment: 'ignored',
      matrixParams: 'ignored',
      queryParams: 'subset',
    };
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Generates a random id
   *
   * @param length
   */
  randomId(length = 10): string {
    const chars =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let name = '';

    for (let i = 0; i < 10; i++) {
      name += chars.charAt(Math.floor(Math.random() * chars.length));
    }

    return name;
  }

  /**
   * Generate a responsive class based on the given column object
   */
  generateResponsiveClass(Columns): string {
    const auxClasses = [];

    if (Columns?.Fullwidth) {
      auxClasses.push('md:w-full md:col-span-12 col-span-1');
    } else {
      if (Columns?.Screen_XS) {
        auxClasses.push(`${Columns.Screen_XS}`);
      } else {
        auxClasses.push('col-span-1');
      }
      if (Columns?.Screen_SM) {
        auxClasses.push(`sm:${Columns.Screen_SM}`);
      }
      if (Columns?.Screen_MD) {
        auxClasses.push(`md:${Columns.Screen_MD}`);
      }
      if (Columns?.Screen_LG) {
        auxClasses.push(`lg:${Columns.Screen_LG}`);
      }
      if (Columns?.Screen_XL) {
        auxClasses.push(`xl:${Columns.Screen_XL}`);
      }
    }

    return auxClasses.join(' ');
  }
}
