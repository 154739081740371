<!-- Button -->
<div
  [matMenuTriggerFor]="languages"
  class="cursor-pointer flex items-center text-md"
>
  <span class="text-gray50-500 font-semibold mr-2"
    ><img
      class="w-full"
      [src]="
        'assets/images/flags/' + flagCodes[activeLang]?.toUpperCase() + '.svg'
      "
      [alt]="'Motorbest in ' + activeLang"
      loading="lazy"
    />
  </span>
</div>

<!-- Language menu -->
<mat-menu [xPosition]="'before'" #languages="matMenu">
  <ng-container *ngFor="let lang of availableLangs; trackBy: trackByFn">
    <button mat-menu-item (click)="setActiveLang(lang.id)">
      <span class="flex items-center">
        <ng-container
          *ngTemplateOutlet="flagImage; context: { $implicit: lang.id }"
        ></ng-container>
        <span class="ml-3">{{ lang.label }}</span>
      </span>
    </button>
  </ng-container>
</mat-menu>

<!-- Flag image template -->
<ng-template let-lang #flagImage>
  <span class="relative w-6 shadow rounded-sm overflow-hidden">
    <span
      class="absolute inset-0 ring-1 ring-inset ring-black ring-opacity-10"
    ></span>
    <img
      class="w-full"
      [src]="'assets/images/flags/' + flagCodes[lang]?.toUpperCase() + '.svg'"
      [alt]="'Motorbest in ' + lang"
      loading="lazy"
    />
  </span>
</ng-template>
