import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SettingsService } from '@core/settings/settings.service';
import { TranslocoService } from '@ngneat/transloco';
import { NewsletterService } from '@targx/services/integrations/newsletter.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'tx-newsletter',
  templateUrl: './newsletter.component.html',
  styleUrls: ['./newsletter.component.scss'],
})
export class NewsletterComponent implements OnInit, OnDestroy {
  newsletterForm: UntypedFormGroup;

  socialLinks = {
    fb: 'https://www.facebook.com/',
    insta: 'https://www.instagram.com/',
    youtube: 'https://www.youtube.com/',
    fb_tours: 'https://www.facebook.com/',
    insta_tours: 'https://www.instagram.com/',
  };

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private _settings: SettingsService,
    private _formBuilder: UntypedFormBuilder,
    private _newsletterService: NewsletterService,
    private _snackBar: MatSnackBar,
    private _translateService: TranslocoService,
  ) {}

  ngOnInit(): void {
    // Create the form
    this.newsletterForm = this._formBuilder.group({
      email: ['', Validators.required],
    });

    this._settings.settings$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((settings) => {
        this.socialLinks.fb = settings.data.attributes.SettingField.find(
          (setting) => setting.name === 'facebook_link',
        ).fieldValue;
        this.socialLinks.insta = settings.data.attributes.SettingField.find(
          (setting) => setting.name === 'instagram_link',
        ).fieldValue;
        this.socialLinks.youtube = settings.data.attributes.SettingField.find(
          (setting) => setting.name === 'youtube_link',
        ).fieldValue;

        this.socialLinks.fb_tours = settings.data.attributes.SettingField.find(
          (setting) => setting.name === 'facebook_tours_link',
        ).fieldValue;
        this.socialLinks.insta_tours =
          settings.data.attributes.SettingField.find(
            (setting) => setting.name === 'instagram_tours_link',
          ).fieldValue;
      });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  /**
   *
   */
  subscribe(): void {
    if (!this.newsletterForm.valid) {
      return;
    }

    this.newsletterForm.disable();

    const result = this._newsletterService.addNewMember(
      this.newsletterForm.value,
    );
    console.log(
      `Successfully added contact as an audience member. The contact's id is ${result.id}.`,
    );
    if (result?.id) {
      this._snackBar.open(
        this._translateService.translate(
          'Successfully subscribed to our newsletter.',
        ),
        this._translateService.translate('Close'),
        {
          duration: 5000,
        },
      );
      this.newsletterForm.reset();
      this.newsletterForm.enable();
    } else {
      this._snackBar.open(
        this._translateService.translate(
          'Failed to subscribe to our newsletter.',
        ),
        this._translateService.translate('Close'),
        {
          duration: 5000,
        },
      );
      this.newsletterForm.enable();
    }
  }
}
